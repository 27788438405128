import heroIcon from '../../../../static/img/Pshiholog-v-chate/hero-icon.svg'
import styled from 'styled-components'
import { Button } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const HeroImage = styled.img.attrs(() => ({
  src: heroIcon
}))`
  width: 588px;
  height: 588px;

  @media (max-width: ${size.lg}) {
    width: 100%;
    height: 454px;
  }

  @media (max-width: ${size.sm}) {
    height: 227px;
  }

  @media (max-width: ${size.xs}) {
    height: 288px;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${size.lg}) {
    order: -1;
  }
`

export const Wrapper = styled.div`
  background-color: #dfebf9;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: ${size.md}) {
    display: block;

    ${Button.NewPrimary}, ${Button.NewOutline} {
      width: 100%;
    }
  }
`

export const InfoContainer = styled.div``
