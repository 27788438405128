import React from 'react'
import styled from 'styled-components'
import { Button } from '../../../../youtalk-storybook/src/ui'
import {
  CardContainer,
  Content,
  StarImage,
  TextWrapper,
  Wrapper
} from './InfoChatCard.styles'
import { Event as GAEvent } from '../../../components/GA'
import { size } from '../../../constants'

const ButtonToForm = styled(Button.NewPrimary).attrs({
  href: '/wizard/',
  onClick: GAEvent.openSemeinyiPageFirstScreen,
  size: 'large',
  type: 'link'
})`
  display: flex;

  @media (max-width: ${size.md}) {
    width: 608px;
  }

  @media (max-width: ${size.sm}) {
    width: 100%;
  }
`

export const InfoChatCard = () => (
  <Wrapper>
    <section>
      <Content>
        <CardContainer>
          <StarImage />
          <TextWrapper>
            Чат психологической поддержки от YouTalk всегда готов помочь вам
            справиться с любыми вызовами, с которыми вы можете столкнуться в
            своей жизни. Сделайте выбор в пользу заботы о себе.
          </TextWrapper>
        </CardContainer>
        <ButtonToForm>Подобрать психолога</ButtonToForm>
      </Content>
    </section>
  </Wrapper>
)
