import React from 'react'

export const CommentsCard = ({ name, img, imgAlt, age, post, title, desc }) => (
  <>
    <div className="comments-card__head comments-card-head">
      <img
        alt={imgAlt}
        className="comments-card-head__ava"
        src={`../img/comments/${img}`}
      />
      <div className="comments-card-head__info">
        <p className="comments-card-head__name">{name}</p>
        <p className="comments-card-head__age">{age}</p>
        <p className="comments-card-head__post">{post}</p>
      </div>
    </div>
    <div className="comments-card__content comments-content">
      <h5
        dangerouslySetInnerHTML={{
          __html: title
        }}
        className="comments-content__title"
      ></h5>
      <p
        dangerouslySetInnerHTML={{
          __html: desc
        }}
        className="comments-content__desc"
      ></p>
    </div>
  </>
)
