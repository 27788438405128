import styled from 'styled-components'
import { Title } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const Wrapper = styled.div`
  background-color: #03a094;
`

export const Content = styled.div`
  padding: 72px 0;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;

  @media (max-width: ${size.lg}) {
    flex-direction: column;
    gap: 16px;
  }

  @media (max-width: ${size.md}) {
    padding: 48px 0;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const TitleWrapper = styled.div`
  flex: 1;
  ${Title.H2} {
    color: ${(props) => props.color ?? '#333333'};
  }
`

export const DescriptionWrapper = styled.div`
  flex: 1;
  color: ${(props) => props.color ?? '#333333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`
