import chatIcon from '../../../../static/img/Pshiholog-v-chate/online-session-chat.svg'
import peopleIcon from '../../../../static/img/Pshiholog-v-chate/online-session-people.svg'
import styled from 'styled-components'
import { Button, Title } from '../../../../youtalk-storybook/src/ui'
import { size } from '../../../constants'

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const Wrapper = styled.div`
  background-color: #2963a3;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const TitleWrapper = styled.div`
  ${Title.H2} {
    color: ${(props) => props.color ?? '#333'};
  }

  margin-bottom: 8px;
`

export const TitleCardWrapper = styled.div`
  ${Title.H2} {
    color: ${(props) => props.color ?? '#333'};
  }
`

export const DescriptionWrapper = styled.div`
  text-align: center;
  color: ${(props) => props.color ?? '#333'};
  margin-bottom: 16px;

  > * {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  @media (max-width: ${size.sm}) {
    margin-bottom: 24px;
  }
`

export const Container = styled.div`
  margin: auto;
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  background: white;
  border-radius: 32px;

  @media (max-width: ${size.sm}) {
    flex-direction: column;
    align-items: center;
  }
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 40px;
`

export const ChatImage = styled.img.attrs(() => ({
  src: chatIcon
}))``

export const PeopleImage = styled.img.attrs(() => ({
  src: peopleIcon
}))``

export const TextWrapper = styled.div`
  flex: 1;
  color: ${(props) => props.color ?? '#333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;

  @media (max-width: ${size.md}) {
    display: block;

    ${Button.NewPrimary}, ${Button.NewOutline} {
      width: 100%;
    }
  }
`
