import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import React from 'react'
import SEO from '../atoms/SEO'
import styled from 'styled-components'
import { AnonymosChat } from '../organisms/PsyhologVChate/AnonymosChat'
import { ChatPsychoSup } from '../organisms/PsyhologVChate/ChatPsychoSup'
import { ChatSteps } from '../organisms/PsyhologVChate/ChatSteps'
import { CommentsSection } from '../organisms/CommentsSection'
import { HowItSection } from '../organisms/HowItSection'
import { HowSessionIsGoing } from '../organisms/PsyhologVChate/HowSessionIsGoing'
import { InfoChatCard } from '../organisms/PsyhologVChate/InfoChatCard'
import { OnlineChat } from '../organisms/PsyhologVChate/OnlineChat'
import { OnlineConsulting } from '../organisms/PsyhologVChate/OnlineConsulting'
import { PsychologistsWithChat } from '../organisms/PsyhologVChate/PsychologistsWithChat'
import { RightChoiseTariffSection } from '../organisms/RightChoiseTariffSection'
import { StartConsultation } from '../organisms/PsyhologVChate/StartConsultation'
import { TariffBlock } from '../organisms/SeoTariffsAndBundles/TariffBlock'
import { TarrifPackages } from '../organisms/SeoTariffsAndBundles/TarrifPackages'
import { color } from '../styles/vars/colors'
import { graphql } from 'gatsby'
import { pick } from 'rambda'
import { size } from '../constants'

const Page = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 76px repeat(2, auto);
  min-height: var(--height);
  background: #dfebf9;

  @media (max-width: ${size.xs}) {
    grid-template-rows: 56px repeat(2, auto);
  }
`

const Main = styled.div`
  & ${RightChoiseTariffSection} {
    background: ${color.background};
    padding-top: 71px;
    padding-bottom: 115px;

    @media (max-width: ${size.lg}) {
      padding-top: 31px;
      padding-bottom: 119px;
    }

    @media (max-width: ${size.md}) {
      padding-top: 24px;
      padding-bottom: 69px;
    }

    @media (max-width: ${size.sm}) {
      padding-top: 26px;
      padding-bottom: 44px;
    }

    @media (max-width: ${size.xs}) {
      padding-top: 16px;
      padding-bottom: 32px;
    }
  }

  & ${HowItSection} {
    @media (max-width: ${size.sm}) {
      padding-top: 40px !important;
    }
  }

  & section {
    max-width: 100vw;
  }
`

const PsychologistinChat = ({ data }) => (
  <App>
    <SEO
      description="Психологическая помощь в чате ✔Более 280 психологов ✔Онлайн сессия от 2000 ₽ ✔Анонимный прием ➨Быстрый подбор специалиста под вашу проблему"
      title="Чат с психологом онлайн — психологическая помощь и консультации в чате анонимно | YouTalk"
    />
    <Page>
      <Header />
      <Main>
        <OnlineConsulting />
        <OnlineChat />
        <ChatPsychoSup />
        <PsychologistsWithChat data={pick(['psychologists'], data)} />
        <TariffBlock />
        <TarrifPackages />
        <AnonymosChat />
        <HowSessionIsGoing />
        <StartConsultation />
        <InfoChatCard />
        <ChatSteps />
        <CommentsSection />
      </Main>
      <Footer />
    </Page>
  </App>
)

export default PsychologistinChat

export const query = graphql`
  query PsychologistinChat {
    psychologists: allPsychologistsData(
      filter: {
        is_active: { in: true }
        sync_messages: { in: true }
        messages: { in: true }
      }
    ) {
      edges {
        node {
          id
          _id
          is_active
          about
          approaches {
            _id
            id
            name
          }
          audio
          birthday_date
          educations {
            speciality
            title
            type
            yearEnd
            yearStart
          }
          english
          family
          surname
          features
          gender
          individual
          messages
          is_active
          name
          non_working_areas {
            _id
            id
            tag
          }
          photo {
            ext
            hash
            url
          }
          professions {
            profession_name
          }
          self_appointment
          sync_messages
          start_of_practice
          video
          work_areas {
            _id
            group {
              name
              type
            }
            id
            tag
          }
        }
      }
      totalCount
    }
  }
`
