import React from 'react'
import { Button, Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'
import {
  ButtonsWrapper,
  CardContainer,
  ChatImage,
  Container,
  DescriptionWrapper,
  ImageWrapper,
  InfoContainer,
  PeopleImage,
  TextWrapper,
  TitleWrapper,
  Wrapper
} from './HowSessionIsGoing.styles'
import { Content } from '../Common.styles'
import { Event as GAEvent } from '../../../components/GA'

export const HowSessionIsGoing = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <InfoContainer>
            <TitleWrapper color="white">
              <Title.H2 style={{ textAlign: 'center' }}>
                Как проходит онлайн-сессия
                <br />в чате с психологами YouTalk?
              </Title.H2>
            </TitleWrapper>
            <DescriptionWrapper color="white">
              <Text.Large semiBold>
                Вы можете выбрать один из двух форматов: синхронная <br />
                или асинхронная переписка с психологом.
              </Text.Large>
            </DescriptionWrapper>
            <CardContainer>
              <Container>
                <ImageWrapper>
                  <PeopleImage />
                </ImageWrapper>
                <InfoContainer>
                  <Title.H3>Синхронная переписка</Title.H3>
                  <TextWrapper>
                    <Text.Large semiBold>
                      Консультация 50 минут в онлайн-режиме. Проходит так же,
                      как обычная консультация, но вместо общения по видеосвязи
                      и голосом, вы с психологом обмениваетесь текстовыми
                      сообщениями.
                    </Text.Large>
                    <Text.Large semiBold>
                      Этот формат хорошо подходит людям, которые не могут
                      уединиться, чтобы созвониться со специалистом по видео.
                    </Text.Large>
                  </TextWrapper>
                </InfoContainer>
              </Container>
              <Container>
                <ImageWrapper>
                  <ChatImage />
                </ImageWrapper>
                <InfoContainer>
                  <Title.H3>Асинхронная переписка</Title.H3>
                  <TextWrapper>
                    <Text.Large semiBold>
                      Неделя общения с психологом в Telegram или Whats App. Вы
                      можете писать психологу в любое время. Психолог будет
                      отвечать минимум 1-2 раза в день, пять дней в неделю.
                    </Text.Large>
                    <Text.Large semiBold>
                      В таком формате специалист будет задавать вопросы, давать
                      рекомендации и упражнения, поддерживать вас. Одна неделя
                      асинхронной переписки соотносится по работе на одной
                      консультации по видеосвязи: вы успеете обсудить примерно
                      одинаковое количество тем.
                    </Text.Large>
                    <Text.Large semiBold>
                      К сожалению, в асинхронной переписке невозможно в полную
                      силу работать с тяжелыми психическими расстройствами,
                      однако такой формат отлично подходит для остальных
                      запросов.
                    </Text.Large>
                  </TextWrapper>
                </InfoContainer>
              </Container>
            </CardContainer>

            <ButtonsWrapper>
              <Button.NewPrimary
                href="/wizard/"
                onClick={() => GAEvent.openNavigate('panicAttackLanding')}
                size="large"
                type="link"
              >
                Подобрать психолога
              </Button.NewPrimary>
            </ButtonsWrapper>
          </InfoContainer>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
