import heroIcon from '../../../../static/img/Pshiholog-v-chate/anonym-icon.svg'
import styled from 'styled-components'
import { size } from '../../../constants'

export const Content = styled.div`
  padding: 72px 0;
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;

  @media (max-width: ${size.md}) {
    padding: 48px 0;
    flex-direction: column;
  }

  @media (max-width: ${size.sm}) {
    padding: 32px 0;
  }
`

export const AnonymImage = styled.img.attrs(() => ({
  src: heroIcon
}))`
  width: 588px;
  height: 486px;

  @media (max-width: ${size.lg}) {
    width: 468px;
    height: 486px;
  }

  @media (max-width: ${size.md}) {
    width: 100%;
    height: 341px;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${size.md}) {
    order: 2;
  }
`

export const Wrapper = styled.div`
  background-color: #fff;
`

export const InfoContainer = styled.div``

export const DescriptionWrapper = styled.div`
  flex: 1;
  color: ${(props) => props.color ?? '#333333'};

  > * {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
`
