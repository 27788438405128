import React from 'react'
import {
  Content,
  DescriptionWrapper,
  TitleWrapper,
  Wrapper
} from './StartConsultation.styles'
import { Grid, Text, Title } from '../../../../youtalk-storybook/src/ui'

export const StartConsultation = () => (
  <Wrapper>
    <section>
      <Grid>
        <Content>
          <TitleWrapper color="white">
            <Title.H2>
              Подберите психолога и начните консультации в чате прямо сейчас
            </Title.H2>
          </TitleWrapper>
          <DescriptionWrapper color="white">
            <Text.Large semiBold>
              Не откладывайте свое благополучие на потом — сделайте шаг к
              позитивным изменениям уже сегодня. Подберите психолога, который
              будет руководствоваться вашими потребностями, и начните
              консультации в чате прямо сейчас. Мы гарантируем вам
              конфиденциальность и поддержку на каждом шагу вашего пути к лучшей
              жизни.
            </Text.Large>
          </DescriptionWrapper>
        </Content>
      </Grid>
    </section>
  </Wrapper>
)
